
  <div class="background">
    <!--<app-nav-menu></app-nav-menu>-->
    <div class="uk-container">
      <div class="text-right">
        <h1 class="display-4 rtl farsi-entezar1 h1style">پيـــاده‌رو...</h1>
        <h2 class="rtl farsi-mitra h2style">برای اندیشیدن در تنهایی</h2>
      </div>
    </div>
  </div>
  
<router-outlet></router-outlet>